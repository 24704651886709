/**
 * @wiki http://49.232.206.51:8080/swagger-ui/index.html
*/
import {
  sendGet,
  sendPost,
} from '@/utils/base.js'

/**
 * @name 注册
*/
export const postSendEmployeeRegister = (data = {}) => {
  return sendPost('/employee/register', data)
}

/**
 * @name 登录
*/
export const postSendEmployeeLogin = (data = {}) => {
  return sendPost('/employee/login', data)
}

/**
 * @name 查询组织
*/
export const getSendSystemDeptAll = (params = {}) => {
  return sendGet('/system/dept/all', params)
}

/**
 * @name 载荷列表
*/
export const getSendSystemInstrumentAll = (params = {}) => {
  return sendGet('/system/instrument/all', params)
}

/**
 * @name 根据ID查询载荷
*/
export const getSendSystemInstrumentQueryByIdentifier = (params = {}) => {
  return sendGet(`/system/instrument/queryByIdentifier/${params.id}`, params)
}

/**
 * @name 卫星列表
*/
export const getSendSystemSatelliteAll = (params = {}) => {
  return sendGet('/system/satellite/all', params)
}

/**
 * @name 卫星列表ByCosparId
*/
export const getSendSystemSatelliteAueryByCosparId = (params = {}) => {
  return sendGet(`/system/satellite/queryByCosparId/${params.id}`, params)
}

/**
 * @name 根据ID查询卫星列表
*/
export const getSendSystemSatellitequeryByInstrument = (params = {}) => {
  return sendGet(`/system/satellite/queryByInstrument/${params.id}`, params)
}

// 以下为筛选条件
/**
 * 查询operationalstatus列表
*/
export const getSendSystemOperationalstatusAll = (params = {}) => {
  return sendGet('/system/operationalstatus/all', params)
}

/**
 * 查询OrbitType列表
*/
export const getSendSystemOrbittypeAll = (params = {}) => {
  return sendGet('/system/orbittype/all', params)
}

/**
 * 查询Owner列表
*/
export const getSendSystemOwnerAll = (params = {}) => {
  return sendGet('/system/owner/all', params)
}

/**
 * 查询Sampling列表
*/
export const getSendSystemSamplingAll = (params = {}) => {
  return sendGet('/system/sampling/all', params)
}

/**
 * 查询Agencies列表
*/
export const getSendSystemAgenciesAll = (params = {}) => {
  return sendGet('/system/agencies/all', params)
}

/**
 * 查询Site列表
*/
export const getSendSystemSiteAll = (params = {}) => {
  return sendGet('/system/site/all', params)
}

/**
 * 查询status列表
*/
export const getSendSystemStatusAll = (params = {}) => {
  return sendGet('/system/status/all', params)
}

/**
 * 查询wavebandregion列表
*/
export const getSendSystemWavebandregionAll = (params = {}) => {
  return sendGet('/system/wavebandregion/all', params)
}

/**
 * 查询geometry列表
*/
export const getSendSystemGeometryAll = (params = {}) => {
  return sendGet('/system/geometry/all', params)
}

/**
 * 查询type列表
*/
export const getSendSystemTypeAll = (params = {}) => {
  return sendGet('/system/type/all', params)
}

/**
 * 查询boundunit列表
*/
export const getSendSystemBoundunitAll = (params = {}) => {
  return sendGet('/system/boundunit/all', params)
}

/**
 * 卫星反馈
*/
export const postSendSystemSatellitefeedbackFeedbackSave = (data = {}) => {
  return sendPost('/system/satellitefeedback/save', data)
}

/**
 * 卫星反馈列表（管理员查看）
*/
export const postSendSystemSatellitefeedbackManagerList = (params = {}) => {
  return sendGet('/system/satellitefeedback/managerList', params)
}

/**
 * 卫星反馈标记处理（管理员操作）
*/
export const postSendSystemSatellitefeedbackManager = (data = {}) => {
  return sendPost(`/system/satellitefeedback/manager?id=${data.id}&state=${data.state}`, data)
}

/**
 * 载荷反馈
*/
export const postSendSystemInstrumentfeedbackFeedbackSave = (data = {}) => {
  return sendPost('/system/instrumentfeedback/save', data)
}

/**
 * 载荷反馈列表（管理员查看）
*/
export const postSendSystemInstrumentfeedbackFeedbackManagerList = (params = {}) => {
  return sendGet('/system/instrumentfeedback/managerList', params)
}

/**
 * 载荷反馈标记处理（管理员操作）
*/
export const postSendSystemInstrumentfeedbackFeedbackManager = (data = {}) => {
  return sendPost(`/system/instrumentfeedback/manager?id=${data.id}&state=${data.state}`, data)
}

/**
 * 服务器详情
*/
export const postSendMonitorServer = (params = {}) => {
  return sendGet('/monitor/server', params)
}

/**
 * 全球信息数据
*/
export const postSendSystemGlobalData = (params = {}) => {
  return sendGet('/system/global/data', params)
}

/**
 * 上传文件
 *
*/
export const postSendSystemUploadUpdateData = (data) => {
  return sendPost('/system/upload/updateData', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * 导出访问统计
*/
export const postSendSystemGlobalDownloadData = (params = {}) => {
  return sendGet('/system/global/download/data', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'responseType': 'blob',
      'responseEncoding': 'utf-8'
    },
    'responseType': 'blob'
  })
}

/**
 * 导出全球访问统计
*/
export const postSendSystemGlobalDownloadDistribution = (params = {}) => {
  return sendGet('/system/global/download/distribution', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'responseType': 'blob',
      'responseEncoding': 'utf-8'
    },
    'responseType': 'blob'
  })
}

/**
 * instrument联动查询
*/
export const conditionInstrument = (params = {}) => {
  return sendGet('/system/condition/instrument', params)
}

/**
 * satellite联动查询
*/
export const conditionSatellite = (params = {}) => {
  return sendGet('/system/condition/satellite', params)
}
