<template>
  <div class="home-page">
    <div class="home-header" style="background-position-y: 0px;">
      <div class="home-wrapper">
        <div class="fl">
          <h1 class="index_h1">{{ $t('title') }}</h1>
        </div>
        <div class="home-header-zwflex">&nbsp;</div>
        <!-- <el-dropdown class="home-el-dropdown" @command="handleCommandLanguage">
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="language === 'en'" command="zh">中文</el-dropdown-item>
            <el-dropdown-item v-else command="en">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <a
          class="home-header-language-btn"
          @click="handleShowSelectLanguage"
        >
          <img v-if="language === 'en'" src="@/assets/images/icon_us.png" alt="" />
          <img v-else src="@/assets/images/icon_cn.png" alt="" />
        </a>
        <div class="fr">
          <div v-if="!token || !userName" class="home-type-show-user">
            <a class="btn-login" @click="jumpToLogin">
              <span class="reg">{{ $t('home.loginBtn') }}</span>
            </a>
            <a class="btn-register" @click="jumpToRegister">
              <span class="log">{{ $t('home.registerBtn') }}</span>
            </a>
          </div>
          <div v-else class="home-type-show-user">
            <el-dropdown @command="handleCommand">
              <a class="btn-register2">
                <span class="log">{{ userName | filterEmail }} <i class="el-icon-arrow-down el-icon--right"></i></span>
              </a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="loginOut">{{ $t('home.loginoutBtn') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="home-content">
        <h1>{{ $t('title') }}</h1>
        <p class="house-num">{{ $t('titleSub') }}</p>
        <el-input
          :placeholder="$t('searchResult.searchPlaceholder')"
          v-model="satelliteName"
          class="home-content-input"
          @keyup.enter.native="handleSearch"
        >
          <template slot="append">
            <span class="home-search-filter" @click="dialogFormVisible = true">
              <i class="el-icon-s-grid"></i>
            </span>
            <span class="home-search-btn" @click="handleSearch">{{ $t('home.searchBtn') }}</span>
          </template>
        </el-input>
      </div>
    </div>
    <div class="home-footer">
      <div class="home-tlogos">
        <img class="home-tlogos-item" src="@/assets/images/logo-qinghua.png" alt="">
        <img class="home-tlogos-item" src="@/assets/images/logo-wuhan.png" alt="">
        <img class="home-tlogos-item" src="@/assets/images/logo-geo.jpg" alt="">
      </div>
      <div class="home-footer-title">{{ $t('home.thanksTitle') }}</div>
      <!-- <p class="home-footer-desc">{{ $t('home.thanksContent') }}</p> -->
      <ul class="home-partner-logo">
        <li
          v-for="item in thanksList"
          :key="item.logo"
        >
          <img
            :src="item.logo"
            :alt="item.name"
          />
        </li>
      </ul>
    </div>

    <el-dialog
      :title="$t('moreSearchDialog.title')"
      :visible.sync="dialogFormVisible"
      width="50%"
    >
      <el-form ref="searchForm" :model="searchForm" label-position="top">
        <el-row :gutter="8">
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.keywords')">
              <el-input v-model="searchForm.satelliteName" :placeholder="$t('searchResult.searchPlaceholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.operStatus')">
              <el-select v-model="searchForm.operStatusConceptName" :placeholder="$t('moreSearchDialog.selectPlaceholder')" filterable>
                <el-option
                  v-for="item in operationalstatus"
                  :key="item.code"
                  :label="item.conceptName"
                  :value="item.conceptName">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.launchDate')">
              <el-date-picker
                style="width: 100%;"
                v-model="searchForm.launchDate"
                type="daterange"
                :range-separator="$t('moreSearchDialog.dataTimeSeparator')"
                :start-placeholder="$t('moreSearchDialog.selectPlaceholder')"
                :end-placeholder="$t('moreSearchDialog.selectPlaceholder')"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.eolDate')">
              <el-date-picker
                v-model="searchForm.eolDate"
                style="width: 100%;"
                type="daterange"
                :range-separator="$t('moreSearchDialog.dataTimeSeparator')"
                :start-placeholder="$t('moreSearchDialog.selectPlaceholder')"
                :end-placeholder="$t('moreSearchDialog.selectPlaceholder')"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <hr>
        <el-row>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.owner')">
              <el-select v-model="searchForm.ownerConceptName" :placeholder="$t('moreSearchDialog.selectPlaceholder')" filterable>
                <el-option
                  v-for="item in owner"
                  :key="item.code"
                  :label="item.conceptName"
                  :value="item.conceptName">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.satelliteAgencies')">
              <el-select v-model="searchForm.satelliteAgency" :placeholder="$t('moreSearchDialog.selectPlaceholder')" filterable>
                <el-option
                  v-for="item in satelliteAgency"
                  :key="item.code"
                  :label="item.conceptName"
                  :value="item.conceptName">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <hr>
        <el-row>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.orbitType')">
              <el-select v-model="searchForm.orbitTypeConceptName" :placeholder="$t('moreSearchDialog.selectPlaceholder')" filterable>
                <el-option
                  v-for="item in orbittype"
                  :key="item.code"
                  :label="item.conceptName"
                  :value="item.conceptName">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.repeatCycle')">
              <el-row>
                <el-col :span="10">
                  <el-input v-model="searchForm.minRepeatCycle" :placeholder="$t('moreSearchDialog.minText')" />
                </el-col>
                <el-col :span="2">
                  <div style="text-align: center;">
                    -
                  </div>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="searchForm.maxRepeatCycle" :placeholder="$t('moreSearchDialog.maxText')" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.period')">
              <el-row>
                <el-col :span="10">
                  <el-input v-model="searchForm.minPeriod" :placeholder="$t('moreSearchDialog.minText')" />
                </el-col>
                <el-col :span="2">
                  <div style="text-align: center;">
                    -
                  </div>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="searchForm.maxPeriod" :placeholder="$t('moreSearchDialog.maxText')" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.ect')">
              <el-time-picker
                v-model="searchForm.ect"
                :placeholder="$t('moreSearchDialog.selectPlaceholder')"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.inclination')">
              <el-row>
                <el-col :span="10">
                  <el-input v-model="searchForm.minInclination" :placeholder="$t('moreSearchDialog.minText')" />
                </el-col>
                <el-col :span="2">
                  <div style="text-align: center;">
                    -
                  </div>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="searchForm.maxInclination" :placeholder="$t('moreSearchDialog.maxText')" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.orbitAltitude')">
              <el-row>
                <el-col :span="10">
                  <el-input v-model="searchForm.minOrbitAltitude" :placeholder="$t('moreSearchDialog.minText')" />
                </el-col>
                <el-col :span="2">
                  <div style="text-align: center;">
                    -
                  </div>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="searchForm.maxOrbitAltitude" :placeholder="$t('moreSearchDialog.maxText')" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.orbitLongitude')">
              <el-row>
                <el-col :span="10">
                  <el-input v-model="searchForm.minOrbitLongitude" :placeholder="$t('moreSearchDialog.minText')" />
                </el-col>
                <el-col :span="2">
                  <div style="text-align: center;">
                    -
                  </div>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="searchForm.maxOrbitLongitude" :placeholder="$t('moreSearchDialog.maxText')" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.launchMass')">
              <el-row>
                <el-col :span="10">
                  <el-input v-model="searchForm.minLaunchMass" :placeholder="$t('moreSearchDialog.minText')" />
                </el-col>
                <el-col :span="2">
                  <div style="text-align: center;">
                    -
                  </div>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="searchForm.maxLaunchMass" :placeholder="$t('moreSearchDialog.maxText')" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="searchFormSpan">
            <el-form-item :label="$t('moreSearchDialog.launchSite')">
              <el-select v-model="searchForm.launchSite" :placeholder="$t('moreSearchDialog.selectPlaceholder')" filterable>
                <el-option
                  v-for="item in site"
                  :key="item.code"
                  :label="item.conceptName"
                  :value="item.conceptName">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancelSeniorSearch">{{ $t('cancelBtn') }}</el-button>
        <el-button type="primary" @click="handleConfirmSeniorSearch">{{ $t('confirmBtn') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('languageConfig.selectLanguage')"
      :visible.sync="selectLanguageVisible"
      width="30%"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <div
            class="select-language-item"
            :class="{
              active: language === 'zh'
            }"
            @click="handleSelectLanguage('zh')"
          >
            <div class="select-language-item-icon">
              <img src="@/assets/images/icon_cn.png" alt="" />
            </div>
            <div class="select-language-item-name">简体中文</div>
            <div class="select-language-item-right">
              <i class="el-icon-check"></i>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div
            class="select-language-item"
            :class="{
              active: language === 'en'
            }"
            @click="handleSelectLanguage('en')"
          >
            <div class="select-language-item-icon">
              <img src="@/assets/images/icon_us.png" alt="" />
            </div>
            <div class="select-language-item-name">English</div>
            <div class="select-language-item-right el-icon-check"></div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSendSystemOperationalstatusAll,
  getSendSystemOrbittypeAll,
  getSendSystemOwnerAll,
  getSendSystemSamplingAll,
  getSendSystemAgenciesAll,
  getSendSystemSiteAll
} from '@/api/index.js'

import {
  loginOut
} from "@/utils/utils.js";

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      searchFormSpan: 8,
      satelliteName: "",
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      formLabelWidth: "120px",
      options: [],
      value: '',
      thanksList: [
        {
          logo: require('@/assets/images/home/logos/CelesTrak.png'),
        },
        {
          logo: require('@/assets/images/home/logos/CEOS.png'),
        },
        {
          logo: require('@/assets/images/home/logos/ESA_Logo_pillars.jpg'),
        },
        {
          logo: require('@/assets/images/home/logos/Gunter_s_Space_Page.png'),
        },
        {
          logo: require('@/assets/images/home/logos/itc-logo@2x.png'),
        },
        {
          logo: require('@/assets/images/home/logos/jonathan.png'),
        },
        {
          logo: require('@/assets/images/home/logos/Nanosats_Database_Logo_Color.png'),
        },
        {
          logo: require('@/assets/images/home/logos/nasa_earth_data.png'),
        },
        {
          logo: require('@/assets/images/home/logos/ucs-logo-b.png'),
        },
        {
          logo: require('@/assets/images/home/logos/unoosa.png'),
        },
        {
          logo: require('@/assets/images/home/logos/WMO.png'),
        },
      ],
      searchForm: {},
      selectLanguageVisible: false
    };
  },
  computed: {
    token () {
      return this.$store.getters.token;
    },
    userName () {
      return this.$store.getters.userName;
    },
    language () {
      return this.$store.getters.language;
    },
    operationalstatus () {
      return this.$store.getters.operationalstatus;
    },
    orbittype () {
      return this.$store.getters.orbittype;
    },
    owner () {
      return this.$store.getters.owner;
    },
    sampling () {
      return this.$store.getters.sampling;
    },
    satelliteAgency () {
      return this.$store.getters.satelliteAgency;
    },
    site () {
      return this.$store.getters.site
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (!this.operationalstatus.length) {
        getSendSystemOperationalstatusAll().then((res) => {
          if (res.code === 1) {
            this.$store.commit('SET_OPERATIONALSTATUS', res.data || [])
          }
        })
      }
      if (!this.orbittype.length) {
        getSendSystemOrbittypeAll().then((res) => {
          if (res.code === 1) {
            this.$store.commit('SET_ORBITTYPE', res.data || [])
          }
        })
      }
      if (!this.owner.length) {
        getSendSystemOwnerAll().then((res) => {
          if (res.code === 1) {
            this.$store.commit('SET_OWNER', res.data || [])
          }
        })
      }
      if (!this.sampling.length) {
        getSendSystemSamplingAll().then((res) => {
          if (res.code === 1) {
            this.$store.commit('SET_SAMPLING', res.data || [])
          }
        })
      }
      if (!this.satelliteAgency.length) {
        getSendSystemAgenciesAll().then((res) => {
          if (res.code === 1) {
            this.$store.commit('SET_SATELLITE_AGENCY', res.data || [])
          }
        })
      }
      if (!this.site.length) {
        getSendSystemSiteAll().then((res) => {
          if (res.code === 1) {
            this.$store.commit('SET_SITE', res.data || [])
          }
        })
      }
    },
    handleClose(done) {
      this.$confirm(this.$t('confirmClose'))
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    jumpToLogin () {
      this.$router.push({
        name: "loginView"
      });
    },
    jumpToRegister() {
      this.$router.push({
        name: "registerView"
      });
    },
    handleCommand(command) {
      if (command === 'loginOut') {
        this.$confirm(this.$t('confirmLoginout'))
        .then(() => {
          loginOut()
          this.$nextTick(() => {
            this.$router.push({
              name: "loginView"
            });
          })
        })
        .catch(() => {});
      }
    },
    handleCommandLanguage(command) {
      if (command === 'zh') {
        this.$store.commit('SET_LANGUAGE', 'zh')
        this.$i18n.locale = 'zh'
      } else if (command === 'en') {
        this.$store.commit('SET_LANGUAGE', 'en')
        this.$i18n.locale = 'en'
      }
    },
    handleSelectLanguage (type) {
      if (type === 'zh') {
        this.$store.commit('SET_LANGUAGE', 'zh')
        this.$i18n.locale = 'zh'
      } else if (type === 'en') {
        this.$store.commit('SET_LANGUAGE', 'en')
        this.$i18n.locale = 'en'
      }
      this.selectLanguageVisible = false;
    },
    handleSearch() {
      this.searchForm = {
        satelliteName: this.satelliteName
      }
      this.$store.commit('SET_SEARCH_FORM', this.searchForm);
      this.$router.push({
        name: 'filterResult'
      });
    },
    handleCancelSeniorSearch() {
      this.searchForm = {};
      this.$refs.searchForm.resetFields();
      this.dialogFormVisible = false;
    },
    handleConfirmSeniorSearch() {
      this.$store.commit('SET_SEARCH_FORM', this.searchForm);
      this.$router.push({
        name: 'filterResult'
      });
    },
    handleShowSelectLanguage () {
      this.selectLanguageVisible = true;
    }
  }
};
</script>

<style lang="less" scoped>
.home-page {
  color: @login-font-color;
  background: @login-font-color;
  // height: 100%;
  .home-header {
    background: #161616 url('@/assets/images/home/banner.jpg') no-repeat;
    background-size: auto 100%;
    background-position: center;
    width: 100%;
    height: 610px;
    padding-top: 30px;
    display: block;
    position: relative;
    .home-wrapper {
      width: 1150px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .fl {
        .index_h1 {
          color: #ffffff;
        }
      }
      .home-type-show-user {
        display: flex;
        flex-direction: row;
        align-items: center;
        .btn-login, .btn-register {
          cursor: pointer;
          padding: 0 10px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          margin-left: 8px;
          color: @theme-color;
          background-color: #FFFFFF;
          font-size: 16px;
          cursor: pointer;
          border-radius: 4px;
          .reg, .log {
            color: @theme-color;
          }
          &:hover {
            opacity: 0.8;
          }
        }
        .btn-register2 {
          cursor: pointer;
          color: #FFFFFF;
          font-size: 16px;
        }
      }
      .home-header-zwflex {
        flex: 1;
      }
      .home-header-language-btn {
        width: 36px;
        height: 36px;
        border-radius: 36px;
        cursor: pointer;
        overflow: hidden;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .home-content {
      width: 1150px;
      margin: 120px auto 0 auto;
      text-align: center;
      h1 {
        font-size: 60px;
      }
      .house-num {
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 4px;
        height: 35px;
        line-height: 35px;
      }
      .home-content-input {
        width: 646px;
        height: 55px;
        ::v-deep {
          .el-input__inner {
            height: 100%;
          }
          .el-input-group__append {
            background-color: @login-font-color;
            padding: 0;
            border: 0;
            .home-search-filter {
              vertical-align: top;
              display: inline-block;
              width: 55px;
              height: 55px;
              line-height: 55px;
              text-align: center;
              background-color: @bg-color;
              cursor: pointer;
              i {
                vertical-align: middle;
                font-size: 32px;
                color: @text-color;
              }
            }
            .home-search-btn {
              vertical-align: top;
              display: inline-block;
              padding: 0 24px;
              height: 55px;
              line-height: 55px;
              background-color: @theme-color;
              font-size: 24px;
              color: @login-font-color;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .select-language-item {
    display: flex;
    border-radius: 4px;
    cursor: pointer;
    flex-direction: row;align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    .select-language-item-icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      border-radius: 24px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
      }
    }
    .select-language-item-name {
      flex: 1;
      font-size: 14px;
    }
    .select-language-item-right {
      width: 20px;
      height: 20px;
      margin-left: 10px;
      font-size: 18px;
      display: none;
    }
    &:hover {
      background: rgba(0, 108, 228, 0.06);
    }
    &.active {
      background: rgba(0, 108, 228, 0.06);
      .select-language-item-name {
        color: @theme-color;
      }
      .select-language-item-right {
        display: block;
        color: @theme-color;
      }
    }
  }
}

.home-footer {
  width: 1150px;
  margin: 0 auto;
  border-top: 1px solid @home-footer-border;
  overflow: hidden;
  .home-tlogos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 32px;
    padding-bottom: 10px;
    .home-tlogos-item {
      margin-right: 40px;
      height: 40px;
    }
  }
  .home-footer-title {
    padding: 32px 0 10px;
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    color: @home-title-color;
  }
  .home-footer-desc {
    font-size: 16px;
    line-height: 22px;
    color: @home-desc-color;
  }
  .home-footer-link-box {
    .home-footer-fl {
      width: 1000px;

      .home-footer-tab span {
        // color: @home-footer-txt;
        float: left;
        margin: 0 20px 12px 0;
        cursor: pointer;
        font-size: 14px;
        transition: all 0.3s ease-in-out;
      }
      .home-footer-tab .hover {
        color: @home-footer-txt-bover;
      }
      .home-footer-link-list {
        clear: both;
        width: 100%;
        overflow: hidden;
        dd a {
          color: @home-footer-dd;
          font-size: 14px;
          display: inline-block;
          margin: 0 10px 10px 0;
        }
      }
    }
  }
  ul.home-partner-logo {
    margin-top: 24px;
    overflow: hidden;
    li {
      float: left;
      margin: 0 40px 20px 0;
      background: @login-font-color;
      img {
        display: block;
        height: 40px;
        width: auto;
        background: @login-font-color;
      }
    }
  }
}
</style>
