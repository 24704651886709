import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'loginView',
    component: () => import(/* webpackChunkName: "loginView" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'registerView',
    component: () => import(/* webpackChunkName: "registerView" */ '../views/Register.vue')
  },
  {
    path: '/filterResult',
    name: 'filterResult',
    component: () => import(/* webpackChunkName: "filterResult" */ '../views/FilterResult.vue')
  },
  {
    path: '/filterInstrument',
    name: 'FilterInstrument',
    component: () => import(/* webpackChunkName: "FilterInstrument" */ '../views/FilterInstrument.vue')
  },
  {
    path: '/examineList',
    name: 'ExamineList',
    component: () => import(/* webpackChunkName: "ExamineList" */ '../views/ExamineList.vue')
  },
  {
    path: '/serviceInformation',
    name: 'ServiceInformation',
    component: () => import(/* webpackChunkName: "ServiceInformation" */ '../views/ServiceInformation.vue')
  },
  {
    path: '/accessStatistics',
    name: 'AccessStatistics',
    component: () => import(/* webpackChunkName: "AccessStatistics" */ '../views/AccessStatistics.vue')
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import(/* webpackChunkName: "AboutView" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
