import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Store from '@/store';
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';
import enLocale from './en';
import zhLocale from './zh';

Vue.use(VueI18n)

export const messages = {
  en: {
    ...elementEnLocale,
    ...enLocale,
  },
  zh: {
    ...elementZhLocale,
    ...zhLocale,
  },
};
export const language = Store.getters.language;
const i18n = new VueI18n({
  locale: language || 'zh',
  messages
})

export default i18n
