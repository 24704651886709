import http from './http'
import { MessageBox, Message } from 'element-ui'
import { getStore } from '@/utils/store';
import router from '@/router'
let unLoginTimes = 0
import i18n from '@/lang'
import {
  loginOut
} from '@/utils/utils'

const baseUrl = '/api'
/**
 * Get请求
 * @param { String } url
 * @param { Object } params
 */
export function sendGet (url, params = {}, config = {}) {
  config.headers = config.headers || {}
  return http.get(baseUrl + url, {
    params,
    ...config,
    headers: {
      ...config.headers,
      Authorization: 'Bearer ' + getStore({
        name: 'token',
        type: 'session'
      }),
    },
  }, config).then(res => {
    const _headers = res.headers
    if (_headers && _headers['content-type'] === 'application/octet-stream') {
      return res
    }
    if (res && res.data) {
      const { code } = res.data
      switch (code) {
        case 401:
          if (!config.unCheckLogin && !unLoginTimes) {
            unLoginTimes = 1
            MessageBox.confirm(i18n.t('dialogLoginAgain'), i18n.t('dialogTitle'), { confirmButtonText: i18n.t('toLoginBtn'), cancelButtonText: i18n.t('cancelBtn'), type: 'warning' }).then(() => {
              unLoginTimes = 0
              router.push({
                name: 'loginView'
              })
            }).catch(() => {
              unLoginTimes = 0
            });
            loginOut()
          }
          return res.data
        default:
          return res.data
      }
    }
    return res.data
  })
}

/**
 *  Post 请求
 * @param { String } url
 * @param { Object } data
 */
export async function sendPost (url, data, config = {}) {
  config.headers = config.headers || {}
  config.headers = {
    ...config.headers,
    Authorization: 'Bearer ' + getStore({
      name: 'token',
      type: 'session'
    })
  }
  const res = await http.post(baseUrl + url, data, config)
  if (res && res.data) {
    if (res.data.code) {
      switch (+res.data.code) {
        case 401:
          if (!config.unCheckLogin && !unLoginTimes) {
            unLoginTimes = 1
            MessageBox.confirm(i18n.t('dialogLoginAgain'), i18n.t('dialogTitle'), { confirmButtonText: i18n.t('toLoginBtn'), cancelButtonText: i18n.t('cancelBtn'), type: 'warning' }).then(() => {
              unLoginTimes = 0
              router.push({
                name: 'loginView'
              })
            }).catch(() => {
              unLoginTimes = 0
            });
            loginOut()
          }
          return res.data
        default:
          return res.data
      }
    } else {
      return res.data
    }
  } else {
    if (!config.hideRequestToast) {
      Message({
        message: '系统繁忙，稍后重试!',
        type: 'warning'
      })
    }
    return null
  }
}
