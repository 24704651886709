<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {
  getSendSystemDeptAll
} from '@/api/index.js'

export default {
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getSendSystemDeptAll().then(res => {
        if (res.code === 1) {
          this.$store.commit('SET_DEPT_ALL', res.data || []);
        }
      })
    }
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: @bg-color;
}

</style>
