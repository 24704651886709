import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from '@/store'
import filters from './filters'
import i18n from './lang/';

// import jsDiff from "jsdiff"
// console.log(jsDiff);

Vue.config.productionTip = false

for (const filterKey in filters) {
  Vue.filter(filterKey, filters[filterKey])
}

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
