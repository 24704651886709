import Vue from 'vue'
import Vuex from 'vuex'
import { setStore, getStore, removeStore } from '@/utils/store';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: getStore({ name: 'language' }) || 'zh',
    deptAll: [],
    token: getStore({ name: 'token' }) || '',
    userName: getStore({ name: 'userName' }) || '',
    role: getStore({ name: 'role' }) || '',
    searchForm: {},
    operationalstatus: [],
    orbittype: [],
    owner: [],
    sampling: [],
    satelliteAgency: [],
    site: [],
    status: [],
    waveBandRegion: [],
    geometry: [],
    type: [],
    boundUnit: [],
    satelliteExamineKeys: ['alternateNames', 'apogee', 'applications', 'cosparId', 'dataPortal', 'dryMass', 'ect', 'eoPortal', 'eolDate', 'hasInstrumentId', 'inclination', 'instrumentIds', 'instrumentNames', 'isEo', 'launchDate', 'launchMass', 'launchSite', 'noradId', 'objectType', 'operStatusCode', 'orbitAltitude', 'orbitCenter', 'orbitLongitude', 'orbitSense', 'orbitType', 'owner', 'perigee', 'period', 'rcs', 'relatedSatIds', 'repeatCycle', 'satelliteAgencies', 'satelliteName', 'webInfo'],
    imstrumentExamineKeys: ['agency', 'alternateName', 'dataFormat', 'description', 'geometry', 'identifier', 'maxSwath', 'name', 'operationalBand', 'relatedIds', 'relatedSatIds', 'resolutionBest', 'revisitTimeBest', 'sampling', 'status', 'type', 'waveBandRegion']
  },
  getters: {
    language: state => state.language,
    deptAll: state => state.deptAll,
    token: state => state.token,
    userName: state => state.userName,
    role: state => state.role,
    searchForm: state => state.searchForm,
    operationalstatus: state => state.operationalstatus,
    orbittype: state => state.orbittype,
    owner: state => state.owner,
    sampling: state => state.sampling,
    satelliteAgency: state => state.satelliteAgency,
    site: state => state.site,
    status: state => state.status,
    waveBandRegion: state => state.waveBandRegion,
    geometry: state => state.geometry,
    type: state => state.type,
    boundUnit: state => state.boundUnit,
    satelliteExamineKeys: state => state.satelliteExamineKeys,
    imstrumentExamineKeys: state => state.imstrumentExamineKeys
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      setStore({
        name: 'language',
        content: state.language,
      });
    },
    SET_DEPT_ALL: (state, deptAll) => {
      state.deptAll = deptAll;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      setStore({
        name: 'token',
        content: state.token,
        type: 'session'
      });
    },
    REMOVE_TOKEN: (state) => {
      state.token = ''
      removeStore({
        name: 'token',
        type: 'session'
      });
    },
    SET_USER_NAME: (state, userName) => {
      state.userName = userName;
      setStore({
        name: 'userName',
        content: state.userName,
        type: 'session'
      });
    },
    REMOVE_USER_NAME: (state) => {
      state.userName = ''
      removeStore({
        name: 'userName',
        type: 'session'
      });
    },
    SET_ROLE: (state, role) => {
      state.role = role;
      setStore({
        name: 'role',
        content: state.role,
        type: 'session'
      });
    },
    REMOVE_ROLE: (state) => {
      state.role = ''
      removeStore({
        name: 'role',
        type: 'session'
      });
    },
    SET_SEARCH_FORM: (state, searchForm) => {
      state.searchForm = searchForm;
    },
    REMOVE_SEARCH_FORM: (state) => {
      state.searchForm = {}
    },
    SET_OPERATIONALSTATUS: (state, operationalstatus) => {
      state.operationalstatus = operationalstatus;
    },
    SET_ORBITTYPE: (state, orbittype) => {
      state.orbittype = orbittype;
    },
    SET_OWNER: (state, owner) => {
      state.owner = owner;
    },
    SET_SAMPLING: (state, sampling) => {
      state.sampling = sampling;
    },
    SET_SATELLITE_AGENCY: (state, satelliteAgency) => {
      state.satelliteAgency = satelliteAgency;
    },
    SET_SITE: (state, site) => {
      state.site = site;
    },
    SET_STATUS: (state, status) => {
      state.status = status;
    },
    SET_WAVE_BAND_REGION: (state, waveBandRegion) => {
      state.waveBandRegion = waveBandRegion;
    },
    SET_GEOMETRY: (state, geometry) => {
      state.geometry = geometry;
    },
    SET_TYPE: (state, type) => {
      state.type = type;
    },
    SET_BOUND_UNIT: (state, boundUnit) => {
      state.boundUnit = boundUnit;
    }
  },
  actions: {
  },
  modules: {
  }
})
