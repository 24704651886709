import axios from 'axios'
import { Loading } from 'element-ui'

const axiosCreate = {
  // 根据环境配置后端api的url
  // baseURL: process.env.VUE_APP_API_BASE,
  // 是否允许后端设置cookie跨域，一般无需改动
  withCredentials: false,
  validateStatus: function (status) {
    // 若状态码大于等于500时则Reject 用来统一处理5XX报错走catch方法
    return status < 500
  },
  timeout: 10 * 60 * 1000
}
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const http = axios.create(axiosCreate)

let downloadLoadingInstance

// 请求数量，全部结束hide loading
let requestCount = 0
function hideLoading () {
  if (requestCount > 0) {
    requestCount--
    if (requestCount === 0) {
      downloadLoadingInstance.close()
    }
  }
}
// 请求拦截
http.interceptors.request.use(config => {
  // show loading
  if (config.showLoading !== false) {
    requestCount++

    downloadLoadingInstance = Loading.service({ text: config.$Loading || '', spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  }
  // if (config.method === 'post' && config.headers['Content-Type'] !== 'multipart/form-data' && config.headers['Content-Type'] !== 'application/json') {
  //   config.data = qs.stringify(config.data, {
  //     allowDots: config.headers.allowDots
  //   })
  // }
  return config
}, error => {
  hideLoading()
  return Promise.reject(error)
})

// 响应拦截
http.interceptors.response.use(response => {
  // hide loading
  hideLoading()
  // 请求成功
  // 1. 根据自己项目需求定制自己的拦截
  // 2. 然后返回数据
  return response
}, error => {
  hideLoading()
  // 请求失败
  if (error && error.response) {
    return Promise.reject(error)
  }
})

export default http
