export default {
  /**
   * @name 邮箱脱敏
   */
  filterEmail: (email) => {
    if (!email) return ''
    const [name, domain] = email.split('@')
    return `${name.substring(0, 1)}***@${domain}`
  }
}
